import React from "react";
import PropTypes from "prop-types";
import { useIntl } from 'gatsby-plugin-intl';
import Header from "./header";
import Footer from "./footer";
import "../sass/main.scss";

interface LayoutProps{
  children: PropTypes.ReactNodeLike
  color: string
}

const Layout = ({ children, color }: LayoutProps) => {

  const title = useIntl().formatMessage({id: "siteTitle"});
  const copyRight = useIntl().formatMessage({id: "copyRight"});

  return (
    <>
      <Header bg={ color } siteTitle={ title }/>
      <main className="section-fade">{ children }</main>
      <Footer copyRight={ copyRight } />
    </>
  )
}

export default Layout;
